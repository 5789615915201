@import "~font-awesome/css/font-awesome.css";

body {
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: linear-gradient(#0d293c, #1b577f) no-repeat center center / 100%
		100%;
}

html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
nav {
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
}

ul {
list-style:none;
margin-left:0;
padding-left:0;
}
li {
  color: #fff;
  font-weight: 700;
}

a {
  color: inherit;
}
a:link,
a:visited {
  color: inherit;
	background-color: transparent;
	text-decoration: none;
	cursor: pointer;
}

a:hover,
a:focus {
  color: inherit;
	outline: 0;
	text-decoration: none;
}

a:active {
	outline: 0;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
iframe,
main,
menu,
section,
summary {
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
}

audio,
canvas,
.video {
	vertical-align: baseline;
	display: inline-block;
}

main p, main h2, main h3 {
	display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
	margin-inline-end: 0px;
	text-align: left;
}

li {
    list-style-type: none;
}

.App {
  text-align: center;
}


.navbar {
	z-index: 999;
	width: 100%;
	/* padding-top: 3vh; */
	position: fixed;
	background-color: #0d293c;
}
.navbar .logo1 {
	width: 180px;
	margin-top: 3px;
}
.navbar .logo1m {
  width: 40px;
}
.navbar #logo1desk {
  width: 180px;
  margin-right: 20px;
}
.navbar #logoHambuger {
  width: 50px;
}
.navbar i {
	font-size: 20px;
}
.connect {
	line-height: 0px;
	padding-top: 7px;
	font-weight: 700;
	font-size: 16px;
}
.navbar .btn {
	border: solid #0f7ba9 2px;
	padding: 15px 10px;
}
.navbar .btn:hover {
	background-color: #0f7ba9;
}

.navbar .btn.recruit {
	border: solid #F00 2px;
	padding: 15px 10px;
}
.navbar .btn.recruit:hover {
	background-color: #700000;
}

.grey {
	color: #bdb4b4cc;
}

/*
  Header
*/

header #logo2 {
  width: 200px;
  margin-top: 35vh;
}
header span {
	font-size: 1.2em;
}
header .row {
	min-height: 100vh;
}

/*
  main
*/
.half-contentL {
	padding-top: 60px;
}
.half-contentR {
	padding-top: 60px;
}
.half-contentRTeam {
	padding-left: 60px;
}

col-md-6 {
	padding: 0px;
}
main .desktop {
	padding: 0px;
}
main .video {
	width: 100%;
	height: auto;
	padding: 0px;
}

main .roof, main .town, main .schema {
	width: 100%;
}

main .linterface {
	background: url("./media/img/InterfaceSC.png"), #FFF;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100% auto;
}
main .premian {
	background: url("./media/img/premianSC.jpg"), #0f7ba9;
	background-position: center right;
	background-repeat: no-repeat;
	background-size: 150% auto;
}
main .premianM {
	width: 100%;
}

main .schema {
	background: url("./media/img/smart-grid.jpg"), #D1DCE2;
	background-position: center right;
	background-repeat: no-repeat;
	background-size: 100% auto;
}


main .visa {
	background-image: url("./media/img/VISA.jpg");
	background-position: right bottom;
	background-repeat: no-repeat;
}
main .visaM {
	width: 100%;
}
main #concept {
	justify-content: start;
	background-image: url("./media/img/bg-blanc.png");
	background-position: right bottom;
	background-repeat: no-repeat;
	/* background-size: 80% 100%; */
}
main h2 {
	font-size: 26px;
	margin-bottom: 0px;
	font-weight: 850;
}
main h3 {
	font-size: 18px;
	font-weight: 300;
	font-size: 1.5em;
}

main #autoconso {
	background-image: url("./media/img/bg-blanc2.png");
	background-color: #FFF;
	background-position: left bottom;
	background-repeat: no-repeat;
	color: #4c4c4c;
	min-height: 50vh;
}

main #techno {
	background-image: url("./media/img/bg-blanc2.png");
	background-color: #0f7ba9;
	background-position: left bottom;
	background-repeat: no-repeat;
	min-height: 50vh;
	color: #FFF;
}
main #projet {
	background-image: url("./media/img/bg-blanc.png");
	background-color: #0f7ba9;
	background-position: right bottom;
	background-repeat: no-repeat;
	min-height: 50vh;
	color: #FFF;
}
main #applications {
	background-image: url("./media/img/bg-blanc.png");
	background-color: rgb(77, 76, 76);
	background-position: right bottom;
	background-repeat: no-repeat;
	min-height: 50vh;
}
main #application {
	background-image: url("./media/img/bg-blanc2.png");
	background-color: #FFF;
	background-position: left bottom;
	background-repeat: no-repeat;
	min-height: 50vh;
	color: #4c4c4c;
}

main .visa {
	background-image: url("./media/img/VISA.jpg");
	background-color: rgb(77, 76, 76);
	background-position: right bottom;
	background-repeat: no-repeat;
	min-height: 50vh;
}
main #team {
	/* background-image: url("./media/img/bg-blanc.png"); */
	background-color: transparent;
	background-position: right bottom;
	background-repeat: no-repeat;
}
main #collaborators {
	background-color: #fff;
}


.smart {
	background-image: url("./media/svg/world.svg") !important;
}
.mobil {
	background-image: url("./media/svg/electric-car.svg") !important;
}
.certif {
	background-image: url("./media/svg/circular-label-with-certified-stamp.svg") !important;
}
.smart, .mobil, .certif {
    width: 100%;
    padding: 80px 0 0;
    background: no-repeat top/auto 70px;
    text-align: center;
}
h5 {
	font-weight: 300;
    font-size: 13px;
}

main .townM, main .roofM {
	width: 100%;
}


/*
 Team
*/

#team {
	background-color: #fff;
}
.pro01 {
	background-image: url("./media/img/profil1.jpg");
}
.pro02 {
	background-image: url("./media/img/profil2.jpg");
}
.pro03 {
	background-image: url("./media/img/profil3.jpg");
}
.pro04 {
	background-image: url("./media/img/profil4.jpg");
}
.pro05 {
	background-image: url("./media/img/profil5.jpg");
}
.pro06 {
	background-image: url("./media/img/profil6.jpg");
}
.pro01, .pro02, .pro03, .pro04, .pro05, .pro06 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	padding: 0px;

}
#team .col-md-4 {
	height: 280px;
	padding: 0px;
	margin: 0px;
	width: 100%;
}

#team .row {
	margin: 0px;
	padding: 0px;
	min-height: 0px !important;
}
#team .container {
	margin: 0px;
	padding: 0px;
	width:100%;
	height: 50%;
}
#team .container-fluid {
	margin: 0px;
	padding: 0px;
	width:100%;
}

main #team .info {
	position: absolute;
	bottom: 0px;
	left: 0px;
	height: 70px;
	width: 100%;
	background-color: rgba(194, 194, 194, 0.56);
	
}
#team p {
	color: #4c4c4c;
}
#team h2, #team h3 {
	color: #4c4c4c;
}
main #team h4 {
	font-size: 15px;
}
main #team span {
	font-size: 12px;
}
main #testimonial, main #clients{
	background-color: #fff;
}

main #clients{
	background-image: url("./media/img/bg-blanc2.png");
	background-color: #FFF;
	background-position: left bottom;
	background-repeat: no-repeat;
	color: #4c4c4c;
	min-height: 50vh;
}

.bodyFiche{
	background-image: url("./media/img/bg-blanc2.png");
	background-color: #FFF;
	background-position: left bottom;
	background-repeat: no-repeat;
	color: #4c4c4c;
	min-height: 50vh;
}

main #clients h4 {
	font-size: 15px;
}

main #clients .card-text {
	font-size: 15px;
	text-align: justify;
}


#testimonial h2, #testimonial h3 {
	color: #4c4c4c;
}
.greentech {
	background-image: url("./media/img/green.png") !important;
}
.derbi {
	background-image: url("./media/img/derbi.png") !important;
}
.greentech, .derbi {
	background-size: 100px;
	background-repeat: no-repeat;
	background-position: left center;
	padding: 14px 120px;
}

footer {
	background-image: linear-gradient(#0d293c,#1b577f);
    padding-top: 50px;
}
footer .fa {
	font-size: 28px;
	padding: 0 10px;
}

footer .emoji {
	width: 20px;
	margin-left: 10px;
}
footer a {
	padding: 0px !important;
	margin: 0px;
	font-weight: 300;
}
footer p {
	font-weight: 300;
}

footer .pad {
	padding-left: 42px;
}
footer .fa-home {
	font-size: 25px;
}
footer .fa-envelope {
	font-size: 20px;
}
footer .fa-phone {
	font-size: 25px;
}

.endDeskMobile {
	background-color: #0d293c;
	padding: 10px 0;
}
.endDeskMobile .modal-content {
	background: #0d293c;
	text-align: center;
}

.endDeskMobile button {
	background: none;
	color: #fff;
	border: none !important;
	text-decoration: none;
	margin-bottom: 5px;
}
.endDeskMobile button:focus {
	border: none !important;
	box-shadow: 0 0 0!important;
}

.endDeskMobile .close {
    color: #fff; 
	opacity: 1;
	font-size: 40px;
	margin-right: 15px !important;
}
.endDeskMobile .close:hover {
    color: #fff !important; 
	opacity: 1 !important;
	box-shadow: 0 0 0;
	text-shadow: 0 0px 0 #fff;
}

.linkBox {
	color: #4c4c4c;
	width: 100% !important;
	height: 100px;
}
.linkBoxL {
	background-color: #D1DCE2;
}
.linkBoxR {
	background-color: #FFF;
}
/*
	desktop
*/
@media (min-width: 992px) {
	.mobile {
		display: none;
	  }
	
	.navbar {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	main #concept, main #team {
		background-position: right bottom;
	}
	main #techno {
		background-position: left bottom;
	}
	main #autoconso {
		background-position: left bottom;
	}
	main #applications {
		background-position: right bottom;
	}
	main #application h4{
		font-size: 16px;
		text-align: left;
	}

	.bgvideo {
		background-color: aqua;
	}
	.videoWrapper {
		position: relative;
		padding-bottom: 52.25%; /* 16:9 */
		margin-top: 50px;
		height: 0px;
	}
	.videoWrapper iframe {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.half-contentL {
		padding-top: 30px;
		padding-left: 14%;
		padding-right: 50px;
	}
	.half-contentR {
		padding-top: 30px;
		padding-left: 50px;
		padding-right: 14%;
	}
	.half-contentRTeam {
		padding-left: 50px;
		padding-right: 14%;
		padding-bottom: 50px;
	}

	.bgImage {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.col-lg-6 {
		padding: 0px;
		width:100%;
	}
	.town {
		background-image: url("./media/img/cloud-solaire.jpg");
	}
	.roof {
		background-image: url("./media/img/bg-techno.jpg");
	}

	.threeBlocks {
		padding-top: 10px;
		padding-bottom: 10px;
	}

}

@media (min-width: 1800px) {
	.bigSize {
		padding-top: 15% !important;
	}
}
/*
	mobile
*/
@media (max-width: 991px) {
	.desktop {
		display: none;
	}
	
}
/*
	logo
*/
@media (min-width: 1081px) {
	.logoNavM {
    display: none;
	}
}
@media (max-width: 1080px) {
	.logoNavD {
		display: none;
	}
}

.example{
	text-align: justify;
}
#collaborators h2, #collaborators	 h3 {
	color: #4c4c4c;
	text-transform: uppercase;
	margin: 0px;
}

/* Carousel */ 
.col-center {
	margin: 0 auto;
	float: none !important;
}
.carousel {
	margin: 20px 50px 40px 50px;
	padding: 0 70px;
}
/* In a cel, tesimonials are not so good  */
.carousel .item {
	color: #999;
	font-size: 14px;
    text-align: center;
	overflow: hidden;
    min-height: 290px;
}
.carousel .item .img-box {
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 30px 0 10px;
	font-size: 25px;
}
.carousel .overview {	
	font-style: italic;
}
.carousel .overview b {
	text-transform: uppercase;
	color: #1b577f;
}
.carousel .carousel-control {
	width: 0px;
    height: 40px;
    margin-top: -20px;
    top: 35%;
	background: none;
}
.carousel-control i {
    font-size: 68px;
	line-height: 42px;
    position: absolute;
    display: inline-block;
	color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel .carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 1px 3px;
	border-radius: 50%;
}
.carousel-indicators li {	
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}


/* Cards */

/* .card-img-top{
	height: 100px;
} */

.card-text{
	min-height: 200px;
}

.card-button{
	min-height: 100px;
}

.btn-primary {
    color: #fff;
    background-color: #1b577f;
    border-color: #1b577f;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0d293c;
    border-color: #0d293c;
}

.card-example{
	background-color: #0d293c;
	border: 1px #000 solid ;
}

.card-text-example{
	text-align: justify;
}


.testimonial_separator {
    margin-top: 5px;
    margin-bottom: 0rem;
    border: 0;
    /* border-top: 1px solid rgba(0,0,0,.1); */
}

.card-title-example {
    margin-bottom: 1.50rem;
}

.card-text-example{
	min-height: 100px;
}



.svg-inline--fa.fa-w-14 {
	width: auto!important;
	padding-left: 2px;
}

.svg-inline--fa.fa-w-10 {
	width: auto!important;
}

.svg-inline--fa.fa-w-15 {
	width: auto!important;
}

.svg-inline--fa.fa-w-12 {
	margin-left: -8px;
}
.svg-inline--fa.fa-w-16 {
	width: auto!important;
}

.testimonial_avatar {
	height: 135px;
	margin: 0 auto;
	padding: 5px;
}

.half-contentL li, #applications li{
	/* margin: 0; */
	margin-left: 30px;
	padding: 2px;
	
	text-align: left;
	list-style:square;
	font-weight: normal;
}
#autoconso li{
	color: #4c4c4c
}

#techno li, #applications li{
	color: #FFF
}


.vertical-timeline-element-date{
	text-align: left;
	color: #fff;
}

.ademe {
    height: 148px;
    width: 158px;
}

.region{ 
	Display: flex;
	Height: 200px; 
	Align-Items: center;
	Justify-Content: center;
}